import React, { useContext, useState } from "react";
import ReactDOM from "react-dom";
import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AuthService from "../../services/auth.service";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import moment from "moment";
import { API_URL } from "../../utilities/url";
import { FiPlus } from "react-icons/fi";
import { HiOutlineRefresh } from "react-icons/hi";
import { FaAddressCard, FaPencilAlt, FaTrash } from "react-icons/fa";
import { base64_enc } from "../../utilities/ext-function";
import { TiWarningOutline } from "react-icons/ti";
import NavLinkContact from "./nav-link-contact";
import storage from "../../services/storage";
import CryptoJS from "crypto-js";
import axios from "axios";
import authHeader from "../../services/auth.header";
import RightComponent from "../contact/component/rightComponent";
import { Rnd } from "react-rnd";
import { LiveChatContext } from "../../provider/liveChatContext";
import SimpleBar from "simplebar-react";
import ModalLiveProfile from "../../components/modalLiveProfile";

var $ = require("jquery");

const Contact = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    centerContactWidth,
    setCenterContactWidth,
    dataMessageContact,
    setDataMessageContact,
    messageContactText,
    setMessageContactText,
    contactASResult,
    setContactASResult,
  } = useContext(LiveChatContext);
  const decryptWS = CryptoJS.AES.decrypt(storage.get("wrs"), "wrs").toString(
    CryptoJS.enc.Utf8
  );
  const workspace = JSON.parse(decryptWS);
  const [selectedGroup, setSelectedGroup] = useState("all");
  const [showModalProfile, setShowModalProfile] = useState(false);
  const [selected, setSelected] = useState(null);
  const [groupList, setGroupList] = useState([]);

  useEffect(() => {
    if (localStorage.getItem("alert")) {
      toast.success("Register Contact Successfull", {
        position: "top-right",
        theme: "colored",
        transition: Slide,
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
      });
      localStorage.removeItem("alert");
    }
    if (localStorage.getItem("alertu")) {
      toast.success("Update Contact Successfull", {
        position: "top-right",
        theme: "colored",
        transition: Slide,
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
      });
      localStorage.removeItem("alertu");
    }

    get_group();
  }, [location.key]);

  useEffect(() => {
    dt_init();
  }, [selectedGroup, location.key]);

  useEffect(() => {
    dt_init();
  }, [contactASResult]);

  const dt_init = () => {
    $("#datatables-reponsive").DataTable({
      dom:
        "<'row'<'col-sm-6'><'col-sm-6'B>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-5'i><'col-sm-7'p>>",
      processing: true,
      serverSide: true,
      bDestroy: true,
      ajax: {
        url: API_URL + "list-table-contacts",
        data: function (data) {
          data.idt_user_workspace = workspace.idt_user_workspace;
          data.group = selectedGroup;
          data.contact = contactASResult.length > 0 ? contactASResult : "all";
        },
        type: "post",
        headers: {
          Authorization: `Bearer ${storage.get("access_token")}`,
        },
        error: function (xhr, error, code) {
          if (code === "Unauthorized") {
            localStorage.clear();
            navigate("/login");
          }
        },
      },
      order: [[5, "desc"]],
      columnDefs: [
        {
          targets: 5,
          createdCell: (td, cellData, rowData, row, col) =>
            ReactDOM.render(
              <div className="d-grid gap-2 d-md-flex justify-content-md">
                <a
                  onClick={() => {
                    setSelected(rowData.msisdn);
                    setShowModalProfile(true);
                  }}
                  className="btn btn-sm btn-success btn-rounded"
                  title="profile"
                >
                  <FaAddressCard size={18} />
                </a>
                <a
                  onClick={() => editTable(cellData)}
                  className="btn btn-sm btn-primary btn-rounded"
                  title="edit"
                >
                  <FaPencilAlt />
                </a>
                <a
                  onClick={() => deleteTable(cellData)}
                  className="btn btn-sm btn-tertiary btn-rounded"
                  title="delete"
                >
                  <FaTrash />
                </a>
              </div>,
              td
            ),
        },
      ],
      columns: [
        {
          data: "name_first",
          render: function (data, type, row) {
            var data = row.fullname;

            return data;
          },
        },
        { data: "title" },
        { data: "email" },
        { data: "msisdn" },
        {
          data: "name_last",
          render: function (data, type, row) {
            var data = row.contact_group;

            return data;
          },
        },
        {
          data: "idt_contact",
        },
      ],
      initComplete: function () {
        var api = this.api();
        $("#btn-dt-search")
          .off(".DT")
          .on("click.DT", function (e) {
            if (contactASResult.length > 0) {
              setContactASResult([]);
            } else {
              api.search($("#dt-search").val()).draw();
            }
          });
        $("#btn-dt-reload")
          .off(".DT")
          .on("click.DT", function (e) {
            $("#dt-search").val(null);
            if (contactASResult.length > 0) {
              setContactASResult([]);
            } else {
              api.search($("#dt-search").val()).draw();
            }
          });
      },
    });
  };

  const get_group = () => {
    axios
      .post(
        API_URL + "contact-group-list",
        {
          idt_user_workspace: workspace.idt_user_workspace,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        if (res.data.success === true) {
          setGroupList(res.data.data);
        } else {
          setGroupList([]);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          navigate("/login");
        }
      });
  };

  const handleGroupChange = (e) => {
    setSelectedGroup(e.target.value);
  };

  function editTable(data) {
    navigate("/contact/edit/" + base64_enc(data));
  }

  function deleteTable(data) {
    axios
      .post(
        API_URL + "delete-contact",
        { idt_contact: data },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          toast.success("Success to delete !", {
            position: "top-right",
            theme: "colored",
            transition: Slide,
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
          });
          $("#datatables-reponsive").DataTable().ajax.reload();
          // $("#datatables-reponsive").DataTable().ajax.reload(null, false);
        } else {
          toast.error(response.data.message, {
            position: "top-right",
            theme: "colored",
            transition: Slide,
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
          });
        }
      })
      .catch((error) => {
        if (error.message == "Request failed with status code 401") {
          AuthService.logout();
          navigate("/login");
        }
      });
  }

  const handleResize = (ref) => {
    const parentWidth = ref.parentNode.offsetWidth;
    const newWidth = (ref.offsetWidth / parentWidth) * 100;
    const minCenterWidth = 65; // Minimum lebar tengah
    const minRightWidth = 5; // Minimum lebar kanan

    if (newWidth >= minCenterWidth && newWidth <= 100 - minRightWidth) {
      setCenterContactWidth(newWidth);
    }
  };

  return (
    <div className="container-fluid p-0">
      <NavLinkContact />
      <div className="d-flex rounded-4 shadow-lg overflow-hidden">
        {/* Wrapper untuk Panel Tengah dan Kanan */}
        <div
          style={{
            display: "flex",
            flexGrow: 1,
            height: "100%",
            width: "70%",
          }}
        >
          {/* Panel Tengah */}
          <Rnd
            size={{ width: `${centerContactWidth}%` }}
            minWidth={"65%"}
            maxWidth={`${100 - 25}%`}
            enableResizing={{ right: true }}
            disableDragging={true}
            onResize={(e, dir, ref) => handleResize(ref)}
            onResizeStop={(e, dir, ref) => handleResize(ref)}
            style={{
              backgroundColor: "#fff",
              boxSizing: "border-box",
              display: "flex",
              position: "relative",
            }}
          >
            <div className="d-contents flex-grow-1">
              <div className="col-md-12 border-end">
                <div className="card-header rounded-4">
                  <h5 className="card-title mt-2">Contact</h5>
                  <ToastContainer />
                </div>
                <SimpleBar className="contact-dt-height">
                  <div className="card-body table-responsive">
                    <div className="d-block mb-3">
                      <div className="d-inline-block mt-1 mb-2">
                        <div className="d-flex align-items-center">
                          {/* Search Input and Buttons */}
                          <div className="input-group input-group-navbar border me-2">
                            <input
                              type="text"
                              id="dt-search"
                              className="form-control form-control-sm h-auto"
                              placeholder="Search"
                              aria-label="Search"
                            />
                            <button
                              className="btn btn-light h-auto"
                              id="btn-dt-search"
                              style={{ backgroundColor: "#e2e2e2" }}
                            >
                              <svg
                                stroke="currentColor"
                                fill="none"
                                strokeWidth="2"
                                viewBox="0 0 24 24"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="align-middle"
                                height="16"
                                width="16"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle cx="11" cy="11" r="8"></circle>
                                <line
                                  x1="21"
                                  y1="21"
                                  x2="16.65"
                                  y2="16.65"
                                ></line>
                              </svg>
                            </button>
                            <button
                              className="btn btn-secondary h-auto"
                              id="btn-dt-reload"
                              style={{
                                backgroundColor: "#e2e2e2",
                                borderLeft: "2px solid #f3f6fb",
                                color: "#fff",
                              }}
                            >
                              <HiOutlineRefresh size={15} />
                            </button>
                          </div>
                          {/* Dropdown (Select) */}
                          <select
                            className="form-select form-select-lg me-2 w-auto d-inline-block"
                            value={selectedGroup}
                            onChange={handleGroupChange}
                          >
                            <option value="all">All Group</option>
                            {groupList.map((data, index) => (
                              <option
                                key={index}
                                value={data.idt_contact_group}
                              >
                                {data.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="d-sm-block float-md-end">
                        <Link
                          to="/contact/add"
                          className="btn btn-primary btn-md"
                        >
                          <FiPlus
                            className="align-middle"
                            size={14}
                            strokeWidth={4}
                          />{" "}
                          Add Contact
                        </Link>
                      </div>
                    </div>
                    <table
                      id="datatables-reponsive"
                      className="table table-striped table-responsive dt-bootstrap5 no-footer"
                    >
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Title</th>
                          <th>Email</th>
                          <th>MSISDN</th>
                          <th>Group</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr></tr>
                      </tbody>
                    </table>
                  </div>
                </SimpleBar>
              </div>
            </div>
          </Rnd>

          {/* Panel Kanan */}
          <div
            className="bg-white flex-grow-1"
            style={{
              borderTopRightRadius: "1rem",
              borderBottomRightRadius: "1rem",
              minWidth: "20%",
            }}
          >
            <div className="row">
              <RightComponent
                dataMessage={dataMessageContact}
                setDataMessage={setDataMessageContact}
                messageText={messageContactText}
                setMessageText={setMessageContactText}
              />
            </div>
          </div>
        </div>
        {/* <div className="col-12">
          <div className="card rounded-4 shadow-lg mb-0">
            <div className="row">
              <div className="col-md-9 border-end">
              </div>
              <div className="col-md-3">
                <RightComponent />
              </div>
            </div>
          </div>
        </div> */}
      </div>
      {/* Modal */}
      <ModalLiveProfile
        show={showModalProfile}
        handleClose={() => setShowModalProfile(false)}
        selectedSession={selected}
      />
      <button
        type="button"
        className="btn btn-primary my-1 d-none"
        data-bs-toggle="modal"
        data-bs-target="#centeredModal"
        id="btn-modal"
      ></button>
      <div
        className="modal fade"
        id="centeredModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-sm modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              {/* <h5 className="modal-title">Centered modal</h5> */}
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                // onClick={backLogin}
              ></button>
            </div>
            <div className="modal-body m-2">
              <div className="text-center">
                <TiWarningOutline size={40} className="mb-2" />
                <p className="mb-0 fs-14 mb-2" id="m_id"></p>
                <p className="mb-0 fs-14 mb-2" id="m_name"></p>
                <p className="mb-0 fs-12" id="m_msisdn"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
