import React, { createContext, useEffect, useState } from "react";
import CryptoJS from "crypto-js";
import storage from "../services/storage";
import { API_URL } from "../utilities/url";
import axios from "axios";

export const ContactContext = createContext();

export const ContactProvider = ({ children }) => {
  const [contactProfiler, setContactProfiler] = useState(null);
  const [contactSupervisor, setContactSupervisor] = useState(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      getContactProfiler();
      getContactSupervisor();
    }, 1500);

    return () => clearTimeout(timer);
  }, []);

  const getContactProfiler = () => {
    const decryptWS = CryptoJS.AES.decrypt(storage.get("wrs"), "wrs").toString(
      CryptoJS.enc.Utf8
    );
    const workspace = JSON.parse(decryptWS);
    axios
      .post(
        `${API_URL}contact-profiler`,
        { idt_workspace: workspace.idt_user_workspace },
        {
          headers: {
            Authorization: `Bearer ${storage.get("access_token")}`,
          },
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          setContactProfiler(response.data.data);
        } else {
          setContactProfiler(null);
        }
      })
      .catch((error) => {
        console.error("Error fetching contact sessions:", error);
        // Tangani error sesuai kebutuhan
      });
  };

  const getContactSupervisor = () => {
    const decryptWS = CryptoJS.AES.decrypt(storage.get("wrs"), "wrs").toString(
      CryptoJS.enc.Utf8
    );
    const workspace = JSON.parse(decryptWS);
    axios
      .post(
        `${API_URL}contact-supervisor`,
        { idt_workspace: workspace.idt_user_workspace },
        {
          headers: {
            Authorization: `Bearer ${storage.get("access_token")}`,
          },
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          setContactSupervisor(response.data.data);
        } else {
          setContactSupervisor(null);
        }
      })
      .catch((error) => {
        console.error("Error fetching contact sessions:", error);
        // Tangani error sesuai kebutuhan
      });
  };

  return (
    <ContactContext.Provider
      value={{
        contactProfiler,
        setContactProfiler,
        getContactProfiler,
        contactSupervisor,
        setContactSupervisor,
        getContactSupervisor,
      }}
    >
      {children}
    </ContactContext.Provider>
  );
};
