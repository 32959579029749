import React, { useCallback, useContext, useRef, useState } from "react";
import { useEffect } from "react";
import "react-toastify/dist/ReactToastify.min.css";
import moment from "moment";
import SimpleBar from "simplebar-react";
import Ava3 from "../../assets/img/avatars/avatar-3.jpg";
import ProfileImg from "../../assets/avatar-broken.png";
import { API_URL_PAYMENT } from "../../utilities/url";
import {
  FaCheck,
  FaLine,
  FaRegSmile,
  FaSnapchatSquare,
  FaTelegramPlane,
  FaTimes,
  FaUserCog,
} from "react-icons/fa";
import { ThemeContext } from "../../provider/themeContext";
import { MdAttachFile, MdFilterList, MdOutlineChat } from "react-icons/md";
import RightComponent from "./component/rightComponent";
import { LiveChatContext } from "../../provider/liveChatContext";
import { Spinner } from "react-bootstrap";
import { IoLogoWhatsapp } from "react-icons/io";
import { RiAttachmentLine } from "react-icons/ri";
import CryptoJS from "crypto-js";
import axios from "axios";
import storage from "../../services/storage";
import { formatTimestamp, truncate } from "../../utilities/ext-function";
import MarkdownRenderer from "./component/markdownRenderer";
import { throttle } from "lodash";
import { HiChevronDoubleDown } from "react-icons/hi";
import Picker from "emoji-picker-react";
import { Rnd } from "react-rnd";
import { ContactContext } from "../../provider/contactContext";
import ModalAssistantSetting from "../../components/modalAssistantSetting";
import ModalLiveProfile from "../../components/modalLiveProfile";

var $ = require("jquery");

const Chat = () => {
  const {
    centerWidth,
    setCenterWidth,
    dataSession,
    setDataSession,
    dataOSA,
    setDataOSA,
    dataDevice,
    setDataDevice,
    loadingSess,
    setLoadingSess,
    searchSession,
    setSearchSession,
    messageText,
    setMessageText,
    messageAssistantText,
    setMessageAssistantText,
    OSAFilter,
    setOSAFilter,
    deviceFilter,
    setDeviceFilter,
    dataMessage,
    setDataMessage,
    dataMessageAssistant,
    setDataMessageAssistant,
    selectedSession,
    setSelectedSession,
    selectedDeviceId,
    setSelectedDeviceId,
    selectedFromtoId,
    setSelectedFromtoId,
    selectedSessionChatByPass,
    setSelectedSessionChatByPass,
    newMessage,
    sendDataMessage,
    setOffsetData,
    typeContactList,
    setTypeContactList,
  } = useContext(LiveChatContext);
  const { contactSupervisor, setContactSupervisor } =
    useContext(ContactContext);
  const { theme } = useContext(ThemeContext);
  const decryptUSR = CryptoJS.AES.decrypt(storage.get("user"), "user").toString(
    CryptoJS.enc.Utf8
  );
  const usr = JSON.parse(decryptUSR);
  const decryptWS = CryptoJS.AES.decrypt(storage.get("wrs"), "wrs").toString(
    CryptoJS.enc.Utf8
  );
  const workspace = JSON.parse(decryptWS);
  const simpleBarRef = useRef(null);
  const simpleBarRef2 = useRef(null);
  const pickerRef = useRef(null);
  const [showFilter, setShowFilter] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [hasMore2, setHasMore2] = useState(true);

  const [scrollTopBeforeLoad, setScrollTopBeforeLoad] = useState(0); // Simpan posisi scroll sebelum memuat data
  const [contentHeightBeforeLoad, setContentHeightBeforeLoad] = useState(0);
  const [scrollTopBeforeLoad2, setScrollTopBeforeLoad2] = useState(0); // Simpan posisi scroll sebelum memuat data
  const [contentHeightBeforeLoad2, setContentHeightBeforeLoad2] = useState(0);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [showPicker, setShowPicker] = useState(false);

  // Emoticon
  useEffect(() => {
    if (showPicker) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPicker]);

  const handleClickOutside = (event) => {
    if (pickerRef.current && !pickerRef.current.contains(event.target)) {
      setShowPicker(false);
    }
  };

  const handleEmojiClick = (emojiData) => {
    setMessageText((prevText) => prevText + emojiData.emoji);
  };

  // Filter
  const changeFilter = () => {
    setShowFilter(!showFilter);
  };

  const handleTypeSessionChat = (data) => {
    if (typeContactList !== data) {
      setTypeContactList(data);
      getSession({
        OSAFilter,
        deviceFilter,
        searchSession,
        typeContactList: data,
      });
    }
  };

  useEffect(() => {
    const scrollElement = simpleBarRef.current?.getScrollElement();
    if (scrollElement) {
      const newContentHeight = scrollElement.scrollHeight;

      // Hitung delta dari tinggi konten sebelum dan sesudah data baru dimuat
      const scrollDelta = newContentHeight - contentHeightBeforeLoad;

      // Set posisi scroll berdasarkan delta
      scrollElement.scrollTop = scrollTopBeforeLoad + scrollDelta;

      // Reset nilai setelah proses selesai
      setContentHeightBeforeLoad(newContentHeight);
    }
  }, [dataMessage]);

  useEffect(() => {
    const scrollElement = simpleBarRef2.current?.getScrollElement();
    if (scrollElement) {
      // Set posisi scroll ke paling atas
      scrollElement.scrollTop = 0;

      // Perbarui tinggi konten setelah perubahan
      const newContentHeight = scrollElement.scrollHeight;
      setContentHeightBeforeLoad2(newContentHeight);
    }
  }, []);

  // Simpan tinggi konten dan posisi scroll sebelum data baru dimuat
  // useEffect(() => {
  //   const scrollElement = simpleBarRef.current?.getScrollElement();
  //   if (scrollElement) {
  //     setContentHeightBeforeLoad(scrollElement.scrollHeight);
  //     setScrollTopBeforeLoad(scrollElement.scrollTop);
  //   }
  // }, [dataMessage]);

  // Panel Left
  const timeoutRef = useRef(null);
  const handleChangeOSAFilter = (event) => {
    setOSAFilter(event.target.value);
    getSession({
      OSAFilter: event.target.value,
      deviceFilter,
      searchSession,
      typeContactList,
    });
  };
  const handleChangeDeviceFilter = (event) => {
    setDeviceFilter(event.target.value);
    getSession({
      OSAFilter,
      deviceFilter: event.target.value,
      searchSession,
      typeContactList,
    });
  };
  const handleSearchSession = (event) => {
    setSearchSession(event.target.value);
    getSession({
      OSAFilter,
      deviceFilter,
      searchSession: event.target.value,
      typeContactList,
    });
  };

  const getSession = ({
    OSAFilter,
    deviceFilter,
    searchSession,
    typeContactList,
  }) => {
    // Hapus timeout sebelumnya
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    if (!searchSession || searchSession.length >= 3) {
      setLoadingSess(true);
      // Set timeout baru untuk memberikan jeda
      timeoutRef.current = setTimeout(() => {
        axios
          .get(API_URL_PAYMENT + "livechat/contactsession", {
            params: {
              offset: 0,
              limit: 100,
              device_id: deviceFilter || null,
              osa_id: OSAFilter || null,
              name: searchSession || null,
              type: typeContactList,
            },
            headers: {
              "X-User-ID": usr.idt_user,
              "X-Workspace-ID": workspace.idt_user_workspace,
            },
          })
          .then((response) => {
            if (response.data.errorcode === "0000") {
              setDataOSA(response.data.data.osa ? response.data.data.osa : []);
              setDataDevice(
                response.data.data.device ? response.data.data.device : []
              );
              const updatedSessions = (
                response.data.data.message_session || []
              ).map((session) => ({
                ...session,
                Counter: 0, // Tambahkan properti Counter
              }));
              setDataSession(updatedSessions);
            } else {
              // setDataOSA([]);
              // setDataDevice([]);
              setDataSession([]);
            }
            setOffsetData(0);
            setHasMore2(true);
            setLoadingSess(false);
          })
          .catch((error) => {
            console.error("Error fetching contact sessions:", error);
          });
      }, 500);
    }
  };

  const handleSessionChatByPass = () => {
    if (selectedSessionChatByPass) {
      axios
        .delete(API_URL_PAYMENT + "livechat/bypass", {
          params: {
            fromto_id: selectedFromtoId,
            device_id: selectedDeviceId,
          },
          headers: {
            "X-User-ID": usr.idt_user,
            "X-Workspace-ID": workspace.idt_user_workspace,
          },
        })
        .then((response) => {
          if (response.data.errorcode === "0000") {
            setSelectedSessionChatByPass(false);
          }
        })
        .catch((error) => {
          console.error("Error fetching contact sessions:", error);
        });
    } else {
      axios
        .post(
          API_URL_PAYMENT + "livechat/bypass",
          {},
          {
            params: {
              fromto_id: selectedFromtoId,
              device_id: selectedDeviceId,
            },
            headers: {
              "X-User-ID": usr.idt_user,
              "X-Workspace-ID": workspace.idt_user_workspace,
            },
          }
        )
        .then((response) => {
          if (response.data.errorcode === "0000") {
            setSelectedSessionChatByPass(true);
          }
        })
        .catch((error) => {
          console.error("Error fetching contact sessions:", error);
        });
    }
  };

  const handleMessageText = (event) => {
    setMessageText(event.target.value);
  };

  const handleSendMessage = () => {
    const payload = {
      module: "livechat",
      data: {
        device_id: selectedSession.IDTDevice,
        email: null,
        direction: "out",
        channel: selectedSession.DeviceType,
        group: null,
        media_id: null,
        media_type: "text",
        media_url: null,
        fromto_id: selectedSession.FromToID,
        fromto_name: selectedSession.Name,
        content: messageText,
        reference_id: null,
      },
    };
    if (messageText || messageText !== "") {
      sendDataMessage(payload);
    }
    // alert(JSON.stringify(payload));
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0]; // Mendapatkan file yang diunggah
    if (file) {
      const isImage = file.type.startsWith("image/");
      if (!isImage && file.type === "") {
        alert("Tipe file tidak valid!");
        e.target.value = "";
        return;
      }

      // Tambahkan file ke daftar pesan
      const payload = {
        module: "livechat",
        data: {
          device_id: selectedSession.IDTDevice,
          email: null,
          direction: "out",
          channel: selectedSession.DeviceType,
          group: null,
          media_id: null,
          media_type: isImage ? "image" : "file",
          media_url: file,
          fromto_id: selectedSession.FromToID,
          fromto_name: selectedSession.Name,
          content: messageText,
          reference_id: null,
        },
      };

      console.log(payload);
      sendDataMessage(payload);
      e.target.value = "";
    }
  };

  const setSessionChat = (FromToID, IDTDevice) => {
    if (selectedFromtoId !== FromToID || selectedDeviceId !== IDTDevice) {
      setShowPicker(false);
      // Get Message
      axios
        .get(API_URL_PAYMENT + "chat/messages", {
          params: {
            count: 30,
            timestamp: Math.floor(Date.now() / 1000),
            device_id: IDTDevice || null,
            fromto_id: FromToID || null,
          },
          headers: {
            "X-User-ID": usr.idt_user,
            "X-Workspace-ID": workspace.idt_user_workspace,
          },
        })
        .then((response) => {
          if (response.data.errorcode === "0000") {
            setHasMore(true);
            setSelectedSession(() => {
              const selected = dataSession.find(
                (item) =>
                  item.IDTDevice === IDTDevice && item.FromToID === FromToID
              );
              if (selected) {
                setDataSession((prevDataSession) =>
                  prevDataSession.map((session) =>
                    session.IDTDevice === IDTDevice &&
                    session.FromToID === FromToID
                      ? { ...session, Counter: 0 }
                      : session
                  )
                );
              }
              return selected;
            });
            setSelectedDeviceId(IDTDevice);
            setSelectedFromtoId(FromToID);
            const sortedMessages = response.data.data
              ? response.data.data.sort(
                  (a, b) => new Date(a.ts_created) - new Date(b.ts_created)
                )
              : [];
            setDataMessage(sortedMessages);
          }
        })
        .catch((error) => {
          console.error("Error fetching contact sessions:", error);
        });

      // Get Message Assistant
      axios
        .get(API_URL_PAYMENT + "livechat/assistant/session", {
          params: {
            count: 30,
            ts_until: Math.floor(Date.now() / 1000),
            // device_id: IDTDevice || null,
            session_fromto_id: FromToID || null,
          },
          headers: {
            "X-User-ID": usr.idt_user,
            "X-Workspace-ID": workspace.idt_user_workspace,
          },
        })
        .then((response) => {
          if (response.data.errorcode === "0000") {
            const sortedMessages = response.data.data.messages
              ? response.data.data.messages.sort(
                  (a, b) => new Date(a.ts_created) - new Date(b.ts_created)
                )
              : [];
            setDataMessageAssistant(sortedMessages);
          }
        })
        .catch((error) => {
          console.error("Error fetching contact sessions:", error);
        });

      // Get Chat Bypass
      axios
        .get(API_URL_PAYMENT + "livechat/bypass", {
          params: {
            fromto_id: FromToID,
            device_id: IDTDevice,
            bypass: true,
          },
          headers: {
            "X-User-ID": usr.idt_user,
            "X-Workspace-ID": workspace.idt_user_workspace,
          },
        })
        .then((response) => {
          if (response.data.errorcode === "0000") {
            setSelectedSessionChatByPass(
              response.data.data ? response.data.data.bypass : false
            );
          } else {
            setSelectedSessionChatByPass(false);
          }
        })
        .catch((error) => {
          console.error("Error fetching contact sessions:", error);
        });
    }
  };

  const loadMoreMessages = async () => {
    if (!hasMore) return; // Jika loading atau tidak ada lagi pesan, hentikan

    // Simpan posisi scroll dan tinggi konten sebelum memuat data
    const scrollElement = simpleBarRef.current?.getScrollElement();
    if (scrollElement) {
      setScrollTopBeforeLoad(scrollElement.scrollTop);
      setContentHeightBeforeLoad(scrollElement.scrollHeight);
    }

    // Ambil pesan lebih banyak
    if (dataMessage.length > 0 && dataMessage[0]?.ts_created) {
      const t = dataMessage[0]?.ts_created;
      const timestamp = Math.floor(new Date(t) / 1000);
      axios
        .get(API_URL_PAYMENT + "chat/messages", {
          params: {
            count: 30,
            timestamp: timestamp,
            device_id: selectedDeviceId || null,
            fromto_id: selectedFromtoId || null,
          },
          headers: {
            "X-User-ID": usr.idt_user,
            "X-Workspace-ID": workspace.idt_user_workspace,
          },
        })
        .then((response) => {
          if (response.data.data) {
            const sortedMessages = response.data.data.sort(
              (a, b) => new Date(a.ts_created) - new Date(b.ts_created)
            );
            setDataMessage((prevMessages) => [
              ...sortedMessages,
              ...prevMessages,
            ]);
          } else {
            setHasMore(false);
          }
        })
        .catch((error) => {
          console.error("Error fetching contact sessions:", error);
        });
    }
  };

  useEffect(() => {
    // Scroll ke bawah hanya saat pemuatan awal
    const scrollElement = simpleBarRef.current?.getScrollElement();
    if (scrollElement) {
      scrollElement.scrollTop = scrollElement.scrollHeight;
    }
  }, [selectedSession, newMessage]);

  /* Scroll to Bottom */
  const scrollToBottom = () => {
    if (simpleBarRef.current) {
      const scrollElement = simpleBarRef.current.getScrollElement();
      scrollElement.scrollTo({
        top: scrollElement.scrollHeight,
        behavior: "smooth",
      });
      setShowScrollButton(false);
    }
  };
  useEffect(() => {
    const handleScroll = () => {
      const { current } = simpleBarRef;
      if (current) {
        const scrollElement = current.getScrollElement();
        const isScrolledUp =
          scrollElement.scrollTop <
          scrollElement.scrollHeight - scrollElement.clientHeight - 400;
        setShowScrollButton(isScrolledUp);
      }
    };

    const { current } = simpleBarRef;
    if (current) {
      const scrollElement = current.getScrollElement();
      scrollElement.addEventListener("scroll", () => {
        handleScroll();
      });

      return () => {
        scrollElement.removeEventListener("scroll", () => {
          handleScroll();
        });
      };
    }
  }, [simpleBarRef]);

  const throttledLoadMoreMessages = throttle(loadMoreMessages, 1000);
  useEffect(() => {
    const handleScroll = () => {
      if (simpleBarRef.current) {
        const scrollElement = simpleBarRef.current.getScrollElement();
        const { scrollTop } = scrollElement;

        // Jika scrollTop mendekati 80px dari atas dan masih ada data untuk dimuat
        if (scrollTop <= 80 && hasMore) {
          throttledLoadMoreMessages();
        }
      }
    };

    const scrollElement = simpleBarRef.current?.getScrollElement();
    if (scrollElement) {
      scrollElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollElement) {
        scrollElement.removeEventListener("scroll", handleScroll);
      }
      throttledLoadMoreMessages.cancel(); // Hentikan throttle saat komponen di-unmount
    };
  }, [hasMore, dataMessage]);

  // Data Session LoadMore
  const loadMoreMessages2 = async ({
    deviceFilter,
    OSAFilter,
    searchSession,
  }) => {
    if (!hasMore2) return;

    const scrollElement = simpleBarRef2.current?.getScrollElement();
    if (scrollElement) {
      setScrollTopBeforeLoad2(scrollElement.scrollTop);
      setContentHeightBeforeLoad2(scrollElement.scrollHeight);
    }

    try {
      // Gunakan callback agar nilai offset terbaru digunakan
      setOffsetData((prevOffset) => {
        const newOffset = prevOffset + 1;
        axios
          .get(API_URL_PAYMENT + "livechat/contactsession", {
            params: {
              offset: newOffset,
              limit: 100,
              device_id: deviceFilter || null,
              osa_id: OSAFilter || null,
              name: searchSession || null,
              type: typeContactList,
            },
            headers: {
              "X-User-ID": usr.idt_user,
              "X-Workspace-ID": workspace.idt_user_workspace,
            },
          })
          .then((response) => {
            if (response.data.data.message_session) {
              setDataSession((prevSessions) => [
                ...prevSessions,
                ...response.data.data.message_session,
              ]);
            } else {
              setHasMore2(false);
            }
          })
          .catch((error) =>
            console.error("Error fetching contact sessions:", error)
          );

        return newOffset; // Pastikan offset diperbarui
      });
    } catch (error) {
      console.error("Error fetching contact sessions:", error);
    }
  };

  // Function untuk menangani scroll event
  const handleScroll2 = () => {
    if (simpleBarRef2.current) {
      const scrollElement = simpleBarRef2.current.getScrollElement();
      const { scrollTop, scrollHeight, clientHeight } = scrollElement;

      // Jika scroll sudah mendekati bawah, load more
      if (scrollTop + clientHeight >= scrollHeight - 50 && hasMore2) {
        throttledLoadMoreMessages2({
          deviceFilter,
          OSAFilter,
          searchSession,
          typeContactList,
        });
      }
    }
  };

  // Gunakan throttle agar tidak terlalu sering memanggil request
  const throttledLoadMoreMessages2 = useCallback(
    throttle((filters) => loadMoreMessages2(filters), 1000),
    [loadMoreMessages2]
  );

  // Pasang event listener pada scroll
  useEffect(() => {
    const scrollElement = simpleBarRef2.current?.getScrollElement();
    if (scrollElement) {
      scrollElement.addEventListener("scroll", handleScroll2);
    }

    return () => {
      if (scrollElement) {
        scrollElement.removeEventListener("scroll", handleScroll2);
      }
      throttledLoadMoreMessages2.cancel();
    };
  }, [hasMore2, deviceFilter, OSAFilter, searchSession]);

  const renderMessages = () => {
    const renderedMessages = [];
    let previousDatestamp = null;

    if (dataMessage.length > 0) {
      dataMessage.forEach((message, index) => {
        const currentDatestamp = moment(message.ts_created).isSame(
          moment(),
          "day"
        )
          ? "Today"
          : moment(message.ts_created).format("MMMM D, YYYY");

        // Sisipkan elemen tanggal jika berbeda dengan tanggal sebelumnya
        if (currentDatestamp !== previousDatestamp) {
          renderedMessages.push(
            <div
              key={`datestamp-${index}`}
              className="datestamp text-center mb-4 pe-none"
            >
              {currentDatestamp}
            </div>
          );
          previousDatestamp = currentDatestamp;
        }

        // Cek Media URL
        let mediaUrl = "";
        if (message.media_type === "image") {
          if (typeof message.media_url === "string" && message.media_url) {
            mediaUrl = message.media_url;
          } else if (
            typeof message.media_url === "object" &&
            message.media_url !== null
          ) {
            mediaUrl = message.media_url.String || "";
          }
        }

        // Tambahkan pesan ke dalam daftar pesan yang akan dirender
        renderedMessages.push(
          <div
            className={
              message.direction === "in"
                ? "chat-message-right pb-4"
                : "chat-message-left pb-4"
            }
            key={`message-${index}`}
          >
            <div>
              {message.direction === "in" ? (
                <img
                  src={message.avatar || ProfileImg}
                  className="rounded-circle border border-1 me-1"
                  alt={message.name || "User"}
                  width="40"
                  height="40"
                />
              ) : (
                <img
                  src={message.avatar || Ava3}
                  className="rounded-circle me-1"
                  alt={message.name || "User"}
                  width="40"
                  height="40"
                />
              )}

              <div className="text-muted small text-center text-nowrap mt-2">
                {moment(message.ts_created).format("HH:mm")}
              </div>
            </div>
            <div
              className={
                message.direction === "in"
                  ? "flex-shrink-1 bg-left-chat-message rounded py-2 px-3 me-3"
                  : "flex-shrink-1 bg-right-chat-message rounded py-2 px-3 ms-3"
              }
            >
              <div
                className={
                  message.direction === "in"
                    ? "fw-bold mb-1 text-end"
                    : "fw-bold mb-1"
                }
              >
                {message.direction == "in"
                  ? message.name
                    ? message.name
                    : message.fromto_id
                  : "Bot"}
              </div>
              {mediaUrl && (
                <img
                  src={mediaUrl}
                  alt="Media"
                  className="img-fluid rounded mb-2"
                />
              )}
              <div className="markdown">
                <MarkdownRenderer markdown={message.content || ""} />
              </div>
            </div>
          </div>
        );
      });
    }

    return renderedMessages;
  };

  const handleResize = (ref) => {
    const parentWidth = ref.parentNode.offsetWidth;
    const newWidth = (ref.offsetWidth / parentWidth) * 100;
    const minCenterWidth = 50; // Minimum lebar tengah
    const minRightWidth = 5; // Minimum lebar kanan

    if (newWidth >= minCenterWidth && newWidth <= 100 - minRightWidth) {
      setCenterWidth(newWidth);
    }
  };

  // Modal
  const [showModalSupervisor, setShowModalSupervisor] = useState(false);
  const [showModalProfile, setShowModalProfile] = useState(false);

  return (
    <div className="d-flex rounded-4 shadow-lg overflow-hidden">
      {/* Panel Kiri */}
      <div
        className="card border-end mb-0"
        style={{
          width: "20%",
          flexShrink: 0,
          borderTopLeftRadius: "1rem",
          borderBottomLeftRadius: "1rem",
        }}
      >
        <div className="row g-0">
          <div className="col-12 list-group">
            <div className="px-3 d-none d-md-block">
              <div className="d-flex align-items-start align-items-center">
                <div className="flex-grow-1">
                  <input
                    type="text"
                    className="form-control my-3 me-2"
                    placeholder="Search..."
                    id="searchName"
                    value={searchSession}
                    onChange={handleSearchSession}
                  />
                </div>
                <div className="ms-2">
                  <MdFilterList
                    size={24}
                    onClick={() => changeFilter()}
                    className="c-pointer"
                    style={{ color: showFilter ? "#FCA311" : "#333" }}
                  />
                </div>
              </div>
              <div className={`mb-3 ${!showFilter ? "d-none" : ""}`}>
                <select
                  className="form-select mb-2"
                  onChange={handleChangeOSAFilter}
                  value={OSAFilter}
                >
                  <option value="">All Chatbot</option>
                  {dataOSA.length > 0
                    ? dataOSA.map((item, key) => (
                        <option key={key} value={item.idt_osa}>
                          {item.osa_name}
                        </option>
                      ))
                    : null}
                </select>
                <select
                  className="form-select"
                  onChange={handleChangeDeviceFilter}
                  value={deviceFilter}
                >
                  <option value="">All Device</option>
                  {dataDevice.length > 0
                    ? dataDevice.map((item, key) => (
                        <option key={key} value={item.IDTDevice}>
                          {item.DeviceName}
                        </option>
                      ))
                    : null}
                </select>
                {/* <Select
                    isMulti
                    styles={CustomStyles(theme)}
                    className="custom-select"
                    maxMenuHeight={200}
                    options={device}
                    placeholder="Select Assistant"
                    onChange={(selected) => {
                      // field.onChange(selected);
                      // clearErrors("device");
                    }}
                    isSearchable={true}
                    // isDisabled={!selectedCategory}
                  /> */}
              </div>
            </div>
            <ul
              className="nav nav-tabs nav-tabs-custom nav-success nav-justified"
              role="tablist"
              style={{ borderBottom: showFilter && "0px" }}
            >
              <li className="nav-item">
                <a
                  className={
                    typeContactList === "allchat"
                      ? "nav-link active cursor-pointer fs-13"
                      : "nav-link cursor-pointer fs-13"
                  }
                  onClick={() => handleTypeSessionChat("allchat")}
                  data-bs-toggle="tab"
                  role="tab"
                >
                  All Chat
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={
                    typeContactList === "mychat"
                      ? "nav-link active cursor-pointer fs-13"
                      : "nav-link cursor-pointer fs-13"
                  }
                  onClick={() => handleTypeSessionChat("mychat")}
                  data-bs-toggle="tab"
                  role="tab"
                >
                  My Chat
                </a>
              </li>
            </ul>
            <div className="tab-content text-muted">
              <div className="tab-pane active" role="tabpanel">
                <SimpleBar
                  className="chat-messages-2"
                  ref={simpleBarRef2}
                  style={{
                    maxHeight: showFilter && "calc(100vh - 386px)",
                  }}
                >
                  <ul
                    className={`position-relative chat-user mt-1 ${
                      showFilter ? "border-top" : ""
                    }`}
                    id="listName"
                  >
                    {!loadingSess ? (
                      dataSession.length > 0 ? (
                        dataSession.map((item) => (
                          <li>
                            <a
                              onClick={(event) => {
                                event.preventDefault();
                                setSessionChat(item.FromToID, item.IDTDevice);
                              }}
                              className={`list-group-item list-group-item-action border-0 px-3 ${
                                selectedFromtoId === item.FromToID
                                  ? "active"
                                  : ""
                              }`}
                            >
                              <div className="d-flex justify-content-between align-items-start">
                                {/* Bagian Kiri */}
                                <div className="d-flex align-items-start">
                                  <img
                                    src={ProfileImg}
                                    className="rounded-circle me-2 border border-1"
                                    alt={item.Name}
                                    width="40"
                                    height="40"
                                  />
                                  <div>
                                    <p className="mb-0">
                                      {item.Name
                                        ? truncate(item.Name, 22)
                                        : truncate(item.FromToID, 22)}
                                    </p>
                                    <p className="mb-1 fs-12 text-muted">
                                      {item.DeviceType === "WHATSAPP" ? (
                                        <IoLogoWhatsapp
                                          size={14}
                                          color="#25D366"
                                          className="me-1"
                                          style={{ marginTop: "-2px" }}
                                        />
                                      ) : item.DeviceType === "TELEGRAM" ? (
                                        <FaTelegramPlane
                                          size={14}
                                          color="#0088CC"
                                          className="me-1"
                                          style={{ marginTop: "-2px" }}
                                        />
                                      ) : item.DeviceType === "LINE" ? (
                                        <FaLine
                                          size={14}
                                          color="#06C655"
                                          className="me-1"
                                          style={{ marginTop: "-2px" }}
                                        />
                                      ) : item.DeviceType === "SNAPCHAT" ? (
                                        <FaSnapchatSquare
                                          size={14}
                                          color="#F7F400"
                                          className="me-1"
                                          style={{ marginTop: "-2px" }}
                                        />
                                      ) : (
                                        <MdOutlineChat
                                          size={14}
                                          color="#FCA311"
                                          className="me-1"
                                          style={{ marginTop: "-2px" }}
                                        />
                                      )}
                                      {truncate(item.FromToID, 22)}
                                    </p>
                                    <p className="mb-1 fs-11 text-muted">
                                      {item.Content}
                                    </p>
                                  </div>
                                </div>

                                {/* Bagian Kanan */}
                                <div className="d-flex flex-column align-items-end">
                                  <span
                                    className="fs-10 text-muted"
                                    style={{
                                      marginTop: "2px",
                                      marginBottom: "2px",
                                    }}
                                  >
                                    {formatTimestamp(item.TSCreated)}
                                  </span>
                                  {item.Counter > 0 && (
                                    <span className="badge bg-success">
                                      {item.Counter}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </a>
                          </li>
                        ))
                      ) : (
                        <div className="text-center mt-3">No Data</div>
                      )
                    ) : (
                      <div className="text-center mt-3">
                        <Spinner className="spinner-border-md" />
                      </div>
                    )}
                  </ul>
                </SimpleBar>
                <hr className="d-block d-lg-none mt-1 mb-0" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Wrapper untuk Panel Tengah dan Kanan */}
      <div
        style={{
          display: "flex",
          flexGrow: 1,
          height: "100%",
          width: "70%",
        }}
      >
        {/* Panel Tengah */}
        <Rnd
          size={{ width: `${centerWidth}%` }}
          minWidth={"50%"}
          maxWidth={`${100 - 20}%`}
          enableResizing={{ right: true }}
          disableDragging={true}
          onResize={(e, dir, ref) => handleResize(ref)}
          onResizeStop={(e, dir, ref) => handleResize(ref)}
          className="h-100"
          style={{
            backgroundColor: "#fff",
            boxSizing: "border-box",
            display: "flex",
            position: "relative",
          }}
        >
          <div className="d-contents flex-grow-1">
            <div className="col-12 border-end">
              <div className="py-2 px-4 border-bottom d-none d-lg-block">
                <div
                  className="py-1"
                  style={{
                    marginBottom: !selectedSession ? "2.5rem" : null,
                  }}
                >
                  {selectedSession && (
                    <div className="position-relative  d-flex align-items-center justify-content-between">
                      {/* Elemen kiri */}
                      <div
                        className="d-flex align-items-center c-pointer"
                        onClick={() => setShowModalProfile(true)}
                      >
                        <div className="me-2">
                          <img
                            src={ProfileImg}
                            className="rounded-circle border border-1"
                            alt="Bertha Martin"
                            width="40"
                            height="40"
                          />
                        </div>
                        <div className="flex-grow-1 ps-3">
                          <strong>
                            {selectedSession.Name
                              ? selectedSession.Name
                              : selectedSession.FromToID}
                          </strong>
                          <div className="text-muted small">
                            {selectedSession.FromToID}
                          </div>
                        </div>
                      </div>

                      {/* Elemen kanan */}
                      <div className="d-flex align-items-center ms-3">
                        <div className="me-3">
                          <p className="mb-0 fw-bold">
                            {selectedSession.OSAName}
                          </p>
                          <div className="text-muted small">
                            <span>{selectedSession.DeviceName}</span>
                          </div>
                        </div>

                        <div
                          className={`custom-switch px-3 ${
                            selectedSessionChatByPass ? "on" : ""
                          }`}
                          onClick={handleSessionChatByPass}
                        >
                          <div className="slider">
                            <div className="slider-icon">
                              {selectedSessionChatByPass ? (
                                <FaCheck />
                              ) : (
                                <FaTimes />
                              )}
                            </div>
                          </div>
                        </div>

                        {/* <div
                          className="ms-2 d-inline-flex align-items-center justify-content-center rounded-circle border border-1 c-pointer"
                          style={{ width: 40, height: 40, padding: "0.4rem" }}
                          onClick={() => setShowModalSupervisor(true)}
                          title="Assistant Setting"
                        >
                          <FaUserCog size={50} />
                        </div> */}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="position-relative">
                <SimpleBar
                  className="chat-messages bg-light-grey p-4"
                  style={{ overflowX: "hidden" }}
                  ref={simpleBarRef}
                >
                  {renderMessages()}
                </SimpleBar>
                {showScrollButton && (
                  <button
                    className="btn btn-orange scroll-to-bottom-btn rounded-circle"
                    onClick={scrollToBottom}
                  >
                    <HiChevronDoubleDown size={24} />
                  </button>
                )}
              </div>

              <div
                className="flex-grow-0 py-3 px-4 border-top bg-white"
                style={{ height: !selectedSessionChatByPass && "64px" }}
              >
                {selectedSessionChatByPass && (
                  <>
                    <div className="input-group">
                      <a className="btn btn-primary btn-rounded rounded-4 px-1 py-1 me-2">
                        <label
                          htmlFor="file-upload"
                          style={{ cursor: "pointer" }}
                        >
                          <RiAttachmentLine size={20} />
                        </label>
                      </a>
                      <input
                        type="file"
                        id="file-upload"
                        className="d-none"
                        onChange={handleFileUpload}
                      />
                      <a
                        className="btn btn-primary btn-rounded rounded-4 px-1 py-1 me-2"
                        onClick={() => setShowPicker(!showPicker)}
                      >
                        <FaRegSmile size={20} />
                      </a>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Type your message"
                        value={messageText}
                        onChange={handleMessageText}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleSendMessage();
                          }
                        }}
                      />
                      <button
                        className="btn btn-primary"
                        onClick={() => handleSendMessage()}
                      >
                        Send
                      </button>
                    </div>
                    {showPicker && (
                      <div ref={pickerRef} className="emoji-picker-container">
                        <Picker onEmojiClick={handleEmojiClick} />
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </Rnd>

        {/* Panel Kanan */}
        <div
          className="bg-white flex-grow-1"
          style={{
            borderTopRightRadius: "1rem",
            borderBottomRightRadius: "1rem",
            minWidth: "10%",
          }}
        >
          <div className="row">
            <RightComponent
              dataMessage={dataMessageAssistant}
              setDataMessage={setDataMessageAssistant}
              messageText={messageAssistantText}
              setMessageText={setMessageAssistantText}
              selectedSession={selectedSession}
              selectedSessionByPass={selectedSessionChatByPass}
              contactSupervisor={contactSupervisor}
              setContactSupervisor={setContactSupervisor}
            />
          </div>
        </div>
      </div>
      {/* Modal */}
      <ModalLiveProfile
        show={showModalProfile}
        handleClose={() => setShowModalProfile(false)}
        selectedSession={selectedSession && selectedSession.FromToID}
      />
      <ModalAssistantSetting
        show={showModalSupervisor}
        handleClose={() => setShowModalSupervisor(false)}
        contactSupervisor={contactSupervisor}
        setContactSupervisor={setContactSupervisor}
      />
    </div>
  );
};

export default Chat;
