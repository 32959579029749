import axios from "axios";
import React, { createContext, useEffect, useRef, useState } from "react";
import { API_URL_PAYMENT, WS_URL } from "../utilities/url";
import storage from "../services/storage";
import CryptoJS from "crypto-js";
import soundMessage from "../assets/sound/notification-message.mp3";

export const LiveChatContext = createContext();

export const LiveChatProvider = ({ children }) => {
  const ws = useRef(null);
  const [loadingSess, setLoadingSess] = useState(false);
  const [dataDevice, setDataDevice] = useState([]);
  const [dataOSA, setDataOSA] = useState([]);
  const [OSAFilter, setOSAFilter] = useState(null);
  const [deviceFilter, setDeviceFilter] = useState(null);
  const [searchSession, setSearchSession] = useState(null);
  const [messageText, setMessageText] = useState("");
  const [messageContactText, setMessageContactText] = useState("");
  const [messageAssistantText, setMessageAssistantText] = useState("");
  const [contactASResult, setContactASResult] = useState([]);
  const [dataSession, setDataSession] = useState([]);
  const [dataMessage, setDataMessage] = useState([]);
  const [dataMessageContact, setDataMessageContact] = useState([]);
  const [dataMessageAssistant, setDataMessageAssistant] = useState([]);
  const [selectedSessionByPass, setSelectedSessionByPass] = useState(null);
  const [selectedSession, setSelectedSession] = useState(null);
  const [selectedDeviceId, setSelectedDeviceId] = useState(null);
  const [selectedFromtoId, setSelectedFromtoId] = useState(null);
  const [newMessage, setNewMessage] = useState(false);
  const [newMessageContact, setNewMessageContact] = useState(false);
  const [newMessageAssistant, setNewMessageAssistant] = useState(false);
  const selectedDeviceIdRef = useRef(selectedDeviceId);
  const selectedFromtoIdRef = useRef(selectedFromtoId);
  const [selectedSessionChatByPass, setSelectedSessionChatByPass] =
    useState(null);
  const [offsetData, setOffsetData] = useState(0);
  const [typeContactList, setTypeContactList] = useState("allchat");
  // Default lebar tengah 60%
  const [centerWidth, setCenterWidth] = useState(70);
  const [centerContactWidth, setCenterContactWidth] = useState(75);

  useEffect(() => {
    setLoadingSess(true);
    const timer = setTimeout(() => {
      getLiveChatContact();
      getAssistantContact();
      getSocket();
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    selectedFromtoIdRef.current = selectedFromtoId;
    selectedDeviceIdRef.current = selectedDeviceId;
  }, [selectedFromtoId, selectedDeviceId]);

  const getSocket = () => {
    const decryptUSR = CryptoJS.AES.decrypt(
      storage.get("user"),
      "user"
    ).toString(CryptoJS.enc.Utf8);
    const usr = JSON.parse(decryptUSR);
    const decryptWS = CryptoJS.AES.decrypt(storage.get("wrs"), "wrs").toString(
      CryptoJS.enc.Utf8
    );
    const workspace = JSON.parse(decryptWS);
    // Establish WebSocket connection
    ws.current = new WebSocket(
      `${WS_URL}notification?user_id=${usr.idt_user}&user_workspace_id=${workspace.idt_user_workspace}`
    );

    ws.current.onopen = () => {
      console.log("WebSocket connection established");
    };

    ws.current.onmessage = (event) => {
      const res = JSON.parse(event.data);
      console.log(res);
      // Device Message
      if (res.type === "device_message") {
        if (
          res.data.fromto_id === selectedFromtoIdRef.current &&
          res.data.device_id === selectedDeviceIdRef.current
        ) {
          setDataMessage((prevMessages) => [...prevMessages, res.data]);
          setNewMessage(res.data.message_id);
        }
        // Set Session
        setDataSession((prevSessions) => {
          const sessionIndex = prevSessions.findIndex(
            (session) =>
              session.IDTDevice === res.data.device_id &&
              session.FromToID === res.data.fromto_id
          );
          const isSameAsSelectedSession =
            selectedDeviceIdRef &&
            selectedFromtoIdRef &&
            selectedDeviceIdRef.current === res.data.device_id &&
            selectedFromtoIdRef.current === res.data.fromto_id;
          if (sessionIndex !== -1) {
            // Update session if found
            const updatedSession = {
              ...prevSessions[sessionIndex],
              IDTMessage: res.data.message_id,
              Content: res.data.content ? res.data.content.slice(0, 50) : null,
              TSCreated: res.data.ts_created,
              Counter: isSameAsSelectedSession
                ? 0
                : (prevSessions[sessionIndex].Counter || 0) + 1,
            };
            // Jika Counter bertambah, mainkan suara notifikasi
            if (!isSameAsSelectedSession) {
              const audio = new Audio(soundMessage);
              audio.play().catch((err) => {
                console.error("Error playing notification sound:", err);
              });
            }
            return [
              updatedSession,
              ...prevSessions.filter((_, index) => index !== sessionIndex),
            ];
          } else {
            // Add new session if not found
            const newSession = {
              IDTDevice: res.data.device_id,
              FromToID: res.data.fromto_id,
              Direction: res.data.direction,
              IDTMessage: res.data.message_id,
              Name: res.data.name,
              DeviceName: null,
              DeviceType: res.data.channel,
              Content: res.data.content ? res.data.content.slice(0, 50) : null,
              TSCreated: res.data.ts_created,
              Counter: 1,
            };
            return [newSession, ...prevSessions];
          }
        });
      } else if (res.type === "contact_assistant") {
        setDataMessageContact((prevMessages) => [...prevMessages, res.data]);
        setNewMessageContact(true);
      } else if (res.type === "contacts_result") {
        if (res.data.contact_id.length > 0) {
          setContactASResult(res.data.contact_id);
        } else {
          setContactASResult([-1]);
        }
        setNewMessageContact(true);
      } else if (res.type === "livechat_assistant") {
        setDataMessageAssistant((prevMessages) => [...prevMessages, res.data]);
        setNewMessageAssistant(res.data.contact_id);
      }
    };

    ws.current.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    // Cleanup WebSocket connection on component unmount
    return () => {
      ws.current.close();
    };
  };

  const closeSocket = () => {
    if (ws.current) {
      ws.current.close();
      console.log("WebSocket connection closed");
    }
  };

  // Fungsi untuk mengirim data message chat
  const sendDataMessage = (payload) => {
    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      ws.current.send(JSON.stringify(payload));
      setMessageText("");
    } else {
      console.error("WebSocket is not open");
    }
  };

  // Fungsi untuk mengirim data message contact assistant
  const sendDataAssistantMessage = (payload) => {
    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      ws.current.send(JSON.stringify(payload));
      setMessageAssistantText("");
    } else {
      console.error("WebSocket is not open");
    }
  };

  // Fungsi untuk mengirim data message contact assistant
  const sendDataContactMessage = (payload) => {
    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      ws.current.send(JSON.stringify(payload));
      setMessageContactText("");
    } else {
      console.error("WebSocket is not open");
    }
  };

  const getLiveChatContact = () => {
    const decryptUSR = CryptoJS.AES.decrypt(
      storage.get("user"),
      "user"
    ).toString(CryptoJS.enc.Utf8);
    const usr = JSON.parse(decryptUSR);
    const decryptWS = CryptoJS.AES.decrypt(storage.get("wrs"), "wrs").toString(
      CryptoJS.enc.Utf8
    );
    const workspace = JSON.parse(decryptWS);
    axios
      .get(API_URL_PAYMENT + "livechat/contactsession", {
        params: {
          offset: offsetData,
          limit: 100,
          type: typeContactList,
        },
        headers: {
          "X-User-ID": usr.idt_user,
          "X-Workspace-ID": workspace.idt_user_workspace,
        },
      })
      .then((response) => {
        if (response.data.errorcode === "0000") {
          setDataOSA(response.data.data.osa ? response.data.data.osa : []);
          setDataDevice(
            response.data.data.device ? response.data.data.device : []
          );
          const updatedSessions = (
            response.data.data.message_session || []
          ).map((session) => ({
            ...session,
            Counter: 0, // Tambahkan properti Counter
          }));
          setDataSession(updatedSessions);
          setDataMessage([]);
          setSelectedSession(null);
        } else {
          setDataOSA([]);
          setDataDevice([]);
          setDataSession([]);
          setDataMessage([]);
          setSelectedSession(null);
        }
        setLoadingSess(false);
        // console.log("Response Data:", response.data);
        // Lakukan sesuatu dengan data yang diterima
      })
      .catch((error) => {
        console.error("Error fetching contact sessions:", error);
        // Tangani error sesuai kebutuhan
      });
  };

  const getAssistantContact = () => {
    const decryptUSR = CryptoJS.AES.decrypt(
      storage.get("user"),
      "user"
    ).toString(CryptoJS.enc.Utf8);
    const usr = JSON.parse(decryptUSR);
    const decryptWS = CryptoJS.AES.decrypt(storage.get("wrs"), "wrs").toString(
      CryptoJS.enc.Utf8
    );
    const workspace = JSON.parse(decryptWS);
    axios
      .get(API_URL_PAYMENT + "contact/assistant/session", {
        params: {
          count: 50,
          ts_until: Math.floor(Date.now() / 1000),
        },
        headers: {
          "X-User-ID": usr.idt_user,
          "X-Workspace-ID": workspace.idt_user_workspace,
        },
      })
      .then((response) => {
        if (response.data.errorcode === "0000") {
          const sortedMessages = response.data.data.messages
            ? response.data.data.messages.sort(
                (a, b) => new Date(a.ts_created) - new Date(b.ts_created)
              )
            : [];
          setDataMessageContact(
            response.data.data.messages ? sortedMessages : []
          );
          // setSelectedSession(null);
        } else {
          // setDataOSA([]);
          // setDataDevice([]);
          // setDataSession([]);
          // setDataMessage([]);
          // setSelectedSession(null);
        }
      })
      .catch((error) => {
        console.error("Error fetching contact sessions:", error);
        // Tangani error sesuai kebutuhan
      });
  };

  return (
    <LiveChatContext.Provider
      value={{
        centerWidth,
        setCenterWidth,
        centerContactWidth,
        setCenterContactWidth,
        dataMessageContact,
        setDataMessageContact,
        dataMessageAssistant,
        setDataMessageAssistant,
        dataOSA,
        setDataOSA,
        dataDevice,
        setDataDevice,
        dataSession,
        setDataSession,
        selectedSession,
        setSelectedSession,
        selectedSessionByPass,
        setSelectedSessionByPass,
        loadingSess,
        setLoadingSess,
        searchSession,
        setSearchSession,
        messageText,
        setMessageText,
        messageContactText,
        setMessageContactText,
        messageAssistantText,
        setMessageAssistantText,
        OSAFilter,
        setOSAFilter,
        deviceFilter,
        setDeviceFilter,
        dataMessage,
        setDataMessage,
        selectedDeviceId,
        setSelectedDeviceId,
        selectedFromtoId,
        setSelectedFromtoId,
        selectedSessionChatByPass,
        setSelectedSessionChatByPass,
        newMessage,
        setNewMessage,
        newMessageContact,
        setNewMessageContact,
        newMessageAssistant,
        setNewMessageAssistant,
        getLiveChatContact,
        getAssistantContact,
        closeSocket,
        sendDataMessage,
        sendDataContactMessage,
        sendDataAssistantMessage,
        contactASResult,
        setContactASResult,
        offsetData,
        setOffsetData,
        typeContactList,
        setTypeContactList,
      }}
    >
      {children}
    </LiveChatContext.Provider>
  );
};
