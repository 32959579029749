import { React, useContext, useEffect, useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { ToastContainer, toast, Slide } from "react-toastify";
import { API_URL } from "../../utilities/url";
import axios from "axios";
import AuthHeader from "../../services/auth.header";
import Select from "react-select";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import CryptoJS from "crypto-js";
import storage from "../../services/storage";
import { FaCheck, FaRegCopy, FaTimes } from "react-icons/fa";
import { v4 as uuidv4 } from "uuid";
import { ToastrContext } from "../../provider/toastrContext";
import { Controller, useForm } from "react-hook-form";
import NavLinkContact from "./nav-link-contact";
import Skeleton from "react-loading-skeleton";
import { ContactContext } from "../../provider/contactContext";

var $ = require("jquery");

const ContactProfiler = () => {
  const location = useLocation();
  const { showCopy } = useContext(ToastrContext);
  const { contactProfiler, setContactProfiler } = useContext(ContactContext);
  const decryptWS = CryptoJS.AES.decrypt(storage.get("wrs"), "wrs").toString(
    CryptoJS.enc.Utf8
  );
  const workspace = JSON.parse(decryptWS);
  const navigate = useNavigate();
  const [assistant, setAssistant] = useState([]);
  const [hideSpiner, setHideSpiner] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isActive, setIsActive] = useState(false);

  const handleToggle = () => {
    setIsActive(!isActive);
    setValue("active", !isActive);
  };

  useEffect(() => {
    getAssistantList();
    setValue("active", false);
    setValue("idt_user_workspace", workspace.idt_user_workspace);
  }, [location.key]);

  useEffect(() => {
    if (assistant.length > 0) {
      if (contactProfiler) {
        setValue("title", contactProfiler.title);
        setValue("prompt", contactProfiler.prompt);
        setIsActive(contactProfiler.is_active === "Y");
        setValue("active", contactProfiler.is_active === "Y");
        setValue(
          "idt_user_assistant_assignment",
          contactProfiler.idt_user_assistant_assignment
        );

        if (contactProfiler.idt_user_assistant) {
          const selectedOption = assistant.find(
            (option) => option.value === contactProfiler.idt_user_assistant
          );
          setValue("assistant", selectedOption);
        }
      }
      setIsLoading(false);
    }
  }, [assistant, contactProfiler]); // Jalankan ulang ketika contactProfiler berubah

  // async function getData() {
  //   axios
  //     .post(
  //       `${API_URL}contact-profiler`,
  //       { idt_workspace: workspace.idt_user_workspace },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${storage.get("access_token")}`,
  //         },
  //       }
  //     )
  //     .then((response) => {
  //       setIsLoading(false);
  //       if (response.data.success === true) {
  //         const resp = response.data.data;
  //         setValue("title", resp.title);
  //         setValue("prompt", resp.prompt);
  //         setIsActive(resp.is_active === "Y" ? true : false);
  //         setValue("active", resp.is_active === "Y" ? true : false);
  //         setValue(
  //           "idt_user_assistant_assignment",
  //           resp.idt_user_assistant_assignment
  //         );
  //         if (resp && resp.idt_user_assistant) {
  //           const selectedOption = assistant.find(
  //             (option) => option.value === resp.idt_user_assistant
  //           );
  //           setValue("assistant", selectedOption);
  //         }
  //       }
  //     })
  //     .catch((error) => {
  //       if (error.response.status === 401) {
  //         localStorage.clear();
  //         navigate("/login");
  //       }
  //     });
  // }

  async function getAssistantList() {
    axios
      .post(
        `${API_URL}get-personal-assistant-list`,
        { idt_workspace: workspace.idt_user_workspace, limit: 500, page: 1 },
        {
          headers: {
            Authorization: `Bearer ${storage.get("access_token")}`,
          },
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          const maps = response.data.data.data.map(function (d) {
            const map = {
              value: d.idt_user_assistant,
              label: d.name,
            };
            return map;
          });
          setAssistant(maps);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          navigate("/login");
        }
      });
  }

  const customStyles = {
    control: (base, state) => ({
      ...base,
      height: 32,
      borderRadius: 4,
      minHeight: 32,
      borderColor: state.isFocused ? "#7f7f7f" : base.borderColor, // Ubah warna border saat fokus
      boxShadow: state.isFocused ? "0 0 0 0.1px #7f7f7f" : base.boxShadow, // Hilangkan shadow bawaan
      "&:hover": {
        borderColor: state.isFocused ? "#7f7f7f" : base.borderColor, // Warna hover
      },
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      padding: 0,
      margin: -2,
    }),
  };

  const {
    handleSubmit,
    register,
    control,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm();

  const selectedDeviceType = watch("device_type");

  const onSubmit = (data) => {
    setHideSpiner(false);
    axios
      .post(API_URL + "save-contact-profiler", data, {
        headers: AuthHeader(),
      })
      .then((res) => {
        if (res.data.success === true) {
          setContactProfiler(res.data.data);
          toast.success(res.data.message, {
            position: "top-right",
            theme: "colored",
            transition: Slide,
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
          });
          setHideSpiner(true);
        } else {
          toast.error(res.data.message, {
            position: "top-right",
            theme: "colored",
            transition: Slide,
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
          });
          setHideSpiner(true);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const handleGenerateWCL = () => {
    const newUUID = uuidv4();
    setValue(
      "webhook_url",
      "https://api.otokata.id:5001/callback?s=" + newUUID
    );
    clearErrors("webhook_url");
  };

  /* Copy */
  const handleCopyClick = () => {
    const webhookUrl = watch("webhook_url");
    if (webhookUrl) {
      navigator.clipboard.writeText(webhookUrl);
      showCopy("Webhook URL copied to clipboard");
    }
  };

  return (
    <div className="container-fluid p-0">
      <NavLinkContact />
      <div className="row">
        <ToastContainer />
        <div className="col-md-12">
          <div className="card rounded-4 shadow-lg">
            <div className="col-md-11 col-sm-12 mx-auto">
              <div className="card-header rounded-4">
                <h5 className="card-title text-center">AI Contact Profiler</h5>
              </div>
              <div className="card-body">
                <div className="card-body h-100">
                  <form id="form-validation" onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                      <div className="col-md-6">
                        {/* Title Name */}
                        <div className="mb-3 row">
                          <label className="col-form-label col-sm-4 text-sm-right">
                            Title<span className="required">*</span>
                          </label>
                          <div className="col-sm-8">
                            {isLoading ? (
                              <Skeleton height={28} />
                            ) : (
                              <>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Title"
                                  {...register("title", {
                                    required: "Title is required",
                                  })}
                                />
                                {errors.title && (
                                  <span className="text-danger">
                                    {errors.title.message}
                                  </span>
                                )}
                              </>
                            )}
                          </div>
                        </div>

                        {/* Assistant */}
                        <div className="mb-3 row">
                          <label className="col-form-label col-sm-4 text-sm-right">
                            Assistant <span className="required">*</span>
                          </label>
                          <div className="col-sm-8">
                            {isLoading ? (
                              <Skeleton height={28} />
                            ) : (
                              <>
                                <Controller
                                  name="assistant"
                                  control={control}
                                  rules={{ required: "Assistant is required" }}
                                  render={({ field }) => (
                                    <Select
                                      {...field}
                                      options={assistant}
                                      styles={customStyles}
                                      placeholder="Select Assistant"
                                    />
                                  )}
                                />
                                {errors.assistant && (
                                  <span className="text-danger">
                                    {errors.assistant.message}
                                  </span>
                                )}
                              </>
                            )}
                          </div>
                        </div>

                        {/* Prompt */}
                        <div className="mb-3 row">
                          <label className="col-form-label col-sm-4 text-sm-right">
                            Prompt <span className="required">*</span>
                          </label>
                          <div className="col-sm-8">
                            {isLoading ? (
                              <Skeleton height={146} />
                            ) : (
                              <>
                                <textarea
                                  className="form-control"
                                  placeholder="Prompt for specific profiling"
                                  name="prompt"
                                  {...register("prompt", {
                                    required: "Prompt is required",
                                  })}
                                  style={{
                                    minHeight: "150px",
                                    overflow: "hidden",
                                  }}
                                />
                                {errors.prompt && (
                                  <span className="text-danger">
                                    {errors.prompt.message}
                                  </span>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        {/* Active  */}
                        <div className="mb-3 row">
                          <label className="col-form-label col-sm-4 text-sm-right">
                            Active
                          </label>
                          <div className="col-sm-8">
                            {isLoading ? (
                              <Skeleton height={28} width={100} />
                            ) : (
                              <>
                                <div
                                  className={`custom-switch-form px-3 ${
                                    isActive ? "on" : ""
                                  }`}
                                  onClick={handleToggle}
                                >
                                  <div className="slider-form">
                                    <div className="slider-icon-form">
                                      {isActive ? <FaCheck /> : <FaTimes />}
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-4 d-flex justify-content-end flex-column flex-sm-row">
                      <button
                        type="submit"
                        className="btn btn-success btn-md text-end w-sm-auto"
                      >
                        <span
                          className={`spinner-border spinner-border-xs me-2 ${
                            hideSpiner ? "d-none" : ""
                          }`}
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactProfiler;
