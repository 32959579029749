import React, { useContext, useEffect, useRef, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { FaUser, FaClipboardList, FaUserCog } from "react-icons/fa"; // Import ikon dari react-icons
import SimpleBar from "simplebar-react";
import CryptoJS from "crypto-js";
import storage from "../../../services/storage";
import { LiveChatContext } from "../../../provider/liveChatContext";
import Ava3 from "../../../assets/img/avatars/avatar-3.jpg";
import ProfileImg from "../../../assets/avatar-broken.png";
import moment from "moment";
import MarkdownRenderer from "../../chat/component/markdownRenderer";
import { throttle } from "lodash";
import { API_URL_PAYMENT } from "../../../utilities/url";
import axios from "axios";
import { Link } from "react-router-dom";
import ModalAssistantSetting from "../../../components/modalAssistantSetting";

const RightComponent = ({
  dataMessage,
  setDataMessage,
  messageText,
  setMessageText,
  selectedSession,
  selectedSessionByPass,
  contactSupervisor,
  setContactSupervisor,
}) => {
  const simpleBarRef = useRef(null);
  const {
    newMessageAssistant,
    setNewMessageAssistant,
    sendDataAssistantMessage,
  } = useContext(LiveChatContext);
  const decryptUSR = CryptoJS.AES.decrypt(storage.get("user"), "user").toString(
    CryptoJS.enc.Utf8
  );
  const dt_user = JSON.parse(decryptUSR);
  const decryptWS = CryptoJS.AES.decrypt(storage.get("wrs"), "wrs").toString(
    CryptoJS.enc.Utf8
  );
  const workspace = JSON.parse(decryptWS);
  const [hasMore, setHasMore] = useState(true);
  const [scrollTopBeforeLoad, setScrollTopBeforeLoad] = useState(0); // Simpan posisi scroll sebelum memuat data
  const [contentHeightBeforeLoad, setContentHeightBeforeLoad] = useState(0);
  const [activeTab, setActiveTab] = useState("assistant"); // Tab aktif

  // Modal
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const scrollElement = simpleBarRef.current?.getScrollElement();
    if (scrollElement) {
      const newContentHeight = scrollElement.scrollHeight;

      // Hitung delta dari tinggi konten sebelum dan sesudah data baru dimuat
      const scrollDelta = newContentHeight - contentHeightBeforeLoad;

      // Set posisi scroll berdasarkan delta
      scrollElement.scrollTop = scrollTopBeforeLoad + scrollDelta;

      // Reset nilai setelah proses selesai
      setContentHeightBeforeLoad(newContentHeight);
    }
  }, [dataMessage]);

  useEffect(() => {
    // Scroll ke bawah hanya saat pemuatan awal
    const scrollElement = simpleBarRef.current?.getScrollElement();
    if (scrollElement) {
      scrollElement.scrollTop = scrollElement.scrollHeight;
    }
  }, [newMessageAssistant]);

  const loadMoreMessages = async () => {
    if (!hasMore) return; // Jika loading atau tidak ada lagi pesan, hentikan

    // Simpan posisi scroll dan tinggi konten sebelum memuat data
    const scrollElement = simpleBarRef.current?.getScrollElement();
    if (scrollElement) {
      setScrollTopBeforeLoad(scrollElement.scrollTop);
      setContentHeightBeforeLoad(scrollElement.scrollHeight);
    }

    // Ambil pesan lebih banyak
    if (dataMessage.length > 0 && dataMessage[0]?.ts_created) {
      const t = dataMessage[0]?.ts_created;
      const timestamp = Math.floor(new Date(t) / 1000);
      axios
        .get(API_URL_PAYMENT + "livechat/assistant/session", {
          params: {
            count: 30,
            ts_until: timestamp,
            session_fromto_id: selectedSession.FromToID,
          },
          headers: {
            "X-User-ID": dt_user.idt_user,
            "X-Workspace-ID": workspace.idt_user_workspace,
          },
        })
        .then((response) => {
          if (response.data.data.messages) {
            const sortedMessages = response.data.data.messages.sort(
              (a, b) => new Date(a.ts_created) - new Date(b.ts_created)
            );
            setDataMessage((prevMessages) => [
              ...sortedMessages,
              ...prevMessages,
            ]);
          } else {
            setHasMore(false);
          }
        })
        .catch((error) => {
          console.error("Error fetching contact sessions:", error);
        });
    }
  };

  const throttledLoadMoreMessages = throttle(loadMoreMessages, 1000);
  useEffect(() => {
    const handleScroll = () => {
      if (simpleBarRef.current) {
        const scrollElement = simpleBarRef.current.getScrollElement();
        const { scrollTop } = scrollElement;

        // Jika scrollTop mendekati 80px dari atas dan masih ada data untuk dimuat
        if (scrollTop <= 80 && hasMore) {
          throttledLoadMoreMessages();
        }
      }
    };

    const scrollElement = simpleBarRef.current?.getScrollElement();
    if (scrollElement) {
      scrollElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollElement) {
        scrollElement.removeEventListener("scroll", handleScroll);
      }
      throttledLoadMoreMessages.cancel(); // Hentikan throttle saat komponen di-unmount
    };
  }, [hasMore, dataMessage]);

  const renderMessages = () => {
    const renderedMessages = [];
    let previousDatestamp = null;

    if (dataMessage.length > 0) {
      dataMessage.forEach((message, index) => {
        const currentDatestamp = moment(message.ts_created).isSame(
          moment(),
          "day"
        )
          ? "Today"
          : moment(message.ts_created).format("MMMM D, YYYY");

        // Sisipkan elemen tanggal jika berbeda dengan tanggal sebelumnya
        if (currentDatestamp !== previousDatestamp) {
          renderedMessages.push(
            <div
              key={`datestamp-${index}`}
              className="datestamp text-center mb-4 pe-none"
            >
              {currentDatestamp}
            </div>
          );
          previousDatestamp = currentDatestamp;
        }

        // Cek Media URL
        let mediaUrl = "";
        if (message.media_type === "image") {
          if (typeof message.media_url === "string" && message.media_url) {
            mediaUrl = message.media_url;
          } else if (
            typeof message.media_url === "object" &&
            message.media_url !== null
          ) {
            mediaUrl = message.media_url.String || "";
          }
        }

        // Tambahkan pesan ke dalam daftar pesan yang akan dirender
        renderedMessages.push(
          <div
            className={
              message.direction === "in"
                ? "chat-message-right pb-4"
                : "chat-message-left pb-4"
            }
            key={`message-${index}`}
          >
            <div>
              {message.direction === "in" ? (
                <img
                  src={message.avatar || ProfileImg}
                  className="rounded-circle border border-1 me-1"
                  alt={message.name || "User"}
                  width="40"
                  height="40"
                />
              ) : (
                <img
                  src={message.avatar || Ava3}
                  className="rounded-circle me-1"
                  alt={message.name || "User"}
                  width="40"
                  height="40"
                />
              )}

              <div className="text-muted small text-center text-nowrap mt-2">
                {moment(message.ts_created).format("HH:mm")}
              </div>
            </div>
            <div
              className={
                message.direction === "in"
                  ? "flex-shrink-1 bg-left-chat-message rounded py-2 px-3 me-3"
                  : "flex-shrink-1 bg-right-chat-message rounded py-2 px-3 ms-3"
              }
            >
              <div
                className={
                  message.direction === "in"
                    ? "fw-bold mb-1 text-end"
                    : "fw-bold mb-1"
                }
              >
                {/* {message.direction == "in"
                  ? message.name
                    ? message.name
                    : message.fromto_id
                  : "Bot"} */}
              </div>
              {mediaUrl && (
                <img
                  src={mediaUrl}
                  alt="Media"
                  className="img-fluid rounded mb-2"
                />
              )}
              <div className="markdown">
                <MarkdownRenderer markdown={message.content || ""} />
              </div>
            </div>
          </div>
        );
      });
    }

    return renderedMessages;
  };

  const templateData = [
    { id: 1, name: "Template 1" },
    { id: 2, name: "Template 2" },
  ];

  const handleMessageText = (event) => {
    setMessageText(event.target.value);
  };

  const handleSendMessage = () => {
    const payload = {
      module: "livechat_assistant",
      data: {
        user_id: dt_user.idt_user,
        workspace_id: workspace.idt_user_workspace,
        direction: "in",
        media_id: null,
        media_type: "text",
        media_url: null,
        session_fromto_id: selectedSession.FromToID,
        content: messageText,
        reference_id: null,
      },
    };
    if (messageText || messageText !== "") {
      sendDataAssistantMessage(payload);
      setNewMessageAssistant(true);
      const params = {
        idt_message_assistant: null,
        ts_created: moment().format("YYYY-MM-DD HH:mm:ss"),
        idt_user: dt_user.idt_user,
        idt_user_workspace: workspace.idt_user_workspace,
        idt_user_assistant: {
          Int32: 0,
          Valid: false,
        },
        module: "livechat_assistant",
        session_fromto_id: {
          String: dataMessage.length > 0 ? selectedSession.FromToID : null,
          Valid: true,
        },
        idt_osa_session: {
          Int32: 0,
          Valid: true,
        },
        content: messageText,
        media_type: "text",
        media_id: {
          String: "",
          Valid: true,
        },
        media_url: {
          String: "",
          Valid: true,
        },
        direction: "in",
      };
      setDataMessage((prevMessages) => [...prevMessages, params]);
    }
    // console.log(JSON.stringify(payload));
  };

  return (
    <div className="container">
      {/* Tab Navigation */}
      <ul className="nav nav-tabs d-flex justify-content-between">
        <li className="nav-item flex-grow-1 text-center">
          <a
            className={`nav-link ${activeTab === "assistant" ? "active" : ""}`}
            onClick={() => setActiveTab("assistant")}
          >
            <FaUser className="mt-1 mb-1" size={20} /> <br /> Assistant
          </a>
        </li>
        <li className="nav-item flex-grow-1 text-center">
          <a
            className={`nav-link ${activeTab === "template" ? "active" : ""}`}
            onClick={() => setActiveTab("template")}
          >
            <FaClipboardList className="mt-1 mb-1" size={20} /> <br /> Template
          </a>
        </li>
        <li className="nav-item flex-grow-1 text-center">
          <a className="nav-link" onClick={() => setShowModal(true)}>
            <FaUserCog className="mt-1 mb-1" size={20} /> <br /> Setting
          </a>
        </li>
      </ul>

      {/* Tab Content */}
      <div className="tab-content">
        {/* Tab Assistant */}
        {activeTab === "assistant" && (
          <div className="tab-pane fade show active">
            <div className="position-relative">
              <SimpleBar
                className="chat-messages bg-light-grey px-3"
                style={{ overflowX: "hidden" }}
              >
                {renderMessages()}
              </SimpleBar>
            </div>

            <div className="flex-grow-0 py-3 px-4 border-top bg-white">
              {contactSupervisor ? (
                contactSupervisor.is_active === "Y" ? (
                  selectedSessionByPass ? (
                    <div className="input-group">
                      <input type="file" id="file-upload" className="d-none" />
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Type your message"
                        value={messageText}
                        onChange={handleMessageText}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleSendMessage();
                          }
                        }}
                      />
                      <button
                        className="btn btn-success"
                        onClick={() => handleSendMessage()}
                      >
                        Send
                      </button>
                    </div>
                  ) : null
                ) : null
              ) : (
                <div className="text-center fw-bold mt-2">
                  <Link to="#" onClick={() => setShowModal(true)}>
                    Please Set Up Your Assistant!
                  </Link>
                </div>
              )}
            </div>
          </div>
        )}

        {/* Tab Template */}
        {activeTab === "template" && (
          <div className="tab-pane fade show active">
            <div className="position-relative">
              <SimpleBar className="chat-messages p-2">
                <div className="px-4">
                  {templateData.map((item) => (
                    <div
                      className="card mb-3 bg-dark c-pointer"
                      key={item.id}
                      onClick={() => alert(item.name)}
                    >
                      <div className="card-body p-2">
                        <p className="mb-0 text-white fs-12">{item.name}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </SimpleBar>
            </div>

            <div className="flex-grow-0 py-3 px-4 border-top bg-white">
              {contactSupervisor ? (
                selectedSessionByPass ? (
                  <div className="input-group">
                    <input type="file" id="file-upload" className="d-none" />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Type your message"
                      value={messageText}
                      onChange={handleMessageText}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleSendMessage();
                        }
                      }}
                    />
                    <button
                      className="btn btn-success"
                      onClick={() => handleSendMessage()}
                    >
                      Send
                    </button>
                  </div>
                ) : null
              ) : (
                <div className="text-center fw-bold mt-2">
                  <Link to="#" onClick={() => setShowModal(true)}>
                    Please Set Up Your Assistant!
                  </Link>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <ModalAssistantSetting
        show={showModal}
        handleClose={() => setShowModal(false)}
        contactSupervisor={contactSupervisor}
        setContactSupervisor={setContactSupervisor}
      />
    </div>
  );
};

export default RightComponent;
