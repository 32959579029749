const CustomStyles = (theme) => ({
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#f9c452 !important" : "000",
    color: state.isSelected ? "#000" : "#000",
    ":hover": {
      backgroundColor: "#ddd",
    },
    cursor: "pointer",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#000",
    padding: 2,
  }),
  input: (provided) => ({
    ...provided,
    color: "#CED4DA",
  }),
  control: (provided, state) => ({
    ...provided,
    borderRadius: 4,
    backgroundColor: "transparent",
    borderRadius: 4,
    minHeight: 32,
    color: "#000",
    borderColor: state.isFocused ? "#7f7f7f" : provided.borderColor, // Ubah warna border saat fokus
    boxShadow: state.isFocused ? "0 0 0 0.1px #7f7f7f" : provided.boxShadow, // Hilangkan shadow bawaan
    "&:hover": {
      borderColor: state.isFocused ? "#7f7f7f" : provided.borderColor, // Warna hover
    },
  }),
  indicatorContainer: (provided) => ({
    ...provided,
    color: "#CED4DA !important",
    padding: 0,
    margin: -2,
    cursor: "pointer",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    color: "#CED4DA !important",
    padding: 0,
    margin: -2,
    cursor: "pointer",
  }),
  clearIndicator: (provided) => ({
    ...provided,
    color: "#CED4DA !important",
    cursor: "pointer",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "#CED4DA !important",
    cursor: "pointer",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    width: "0px",
  }),
});

export default CustomStyles;
