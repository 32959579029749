import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import Skeleton from "react-loading-skeleton";
import { FaCheck, FaTimes } from "react-icons/fa";
import { ToastContainer, toast, Slide } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import authHeader from "../services/auth.header";
import axios from "axios";
import { API_URL } from "../utilities/url";
import storage from "../services/storage";
import CryptoJS from "crypto-js";
import ProfileImg from "../assets/avatar-broken.png";
import moment from "moment";
import SimpleBar from "simplebar-react";

const ModalLiveProfile = ({ show, handleClose, selectedSession }) => {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    reset,
  } = useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const decryptWS = CryptoJS.AES.decrypt(storage.get("wrs"), "wrs").toString(
    CryptoJS.enc.Utf8
  );

  useEffect(() => {
    if (show) {
      setIsLoading(true);
      axios
        .post(
          API_URL + "contact",
          {
            fromtoid: selectedSession,
          },
          {
            headers: {
              Authorization: `Bearer ${storage.get("access_token")}`,
            },
          }
        )
        .then((res) => {
          if (res.data.success === true) {
            setData(res.data.data);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            localStorage.clear();
            navigate("/login");
          }
        });
    }
  }, [show]);
  const workspace = JSON.parse(decryptWS);
  //   const [assistant, setAssistant] = useState([]);
  //   const [hideSpiner, setHideSpiner] = useState(true);
  //   const [isLoading, setIsLoading] = useState(true);
  const [isActive, setIsActive] = useState(false);

  //   useEffect(() => {
  //     getAssistantList();
  //   }, [location.key]);

  return (
    <Modal show={show} onHide={handleClose} centered backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Profile</Modal.Title>
      </Modal.Header>
      <SimpleBar
        style={{
          display: "flex",
          flexDirection: "column",
          maxHeight: "calc(100vh - 150px)",
        }}
      >
        <Modal.Body>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-4">
                <img
                  src={ProfileImg}
                  class="img-top-profile img-fluid rounded-circle border border-2 border-secondary me-4"
                  height="150"
                  width="150"
                ></img>
              </div>
              <div className="col-md-8">
                {!isLoading ? (
                  <>
                    <p className="fs-18 fw-bold mb-0">
                      {data
                        ? data.name_first +
                          (data.name_last ? " " + data.name_last : "")
                        : null}
                    </p>
                    <p className="fs-14 fw-bold mb-0">
                      {data ? data.msisdn : null}
                    </p>
                    <p className="fs-14 fw-bold mb-1">
                      {data ? data.email : null}
                    </p>
                    {/* <p className="fs-11 fw-bold mb-3">
                      Last Chat:{" "}
                      {selectedSession
                        ? moment(selectedSession.TSCreated).format(
                            "DD MMM YYYY | HH:mm"
                          )
                        : null}
                    </p> */}
                    <p className="fs-14 fw-bold mb-0">
                      {data ? data.address : null}
                    </p>
                    <p className="fs-14 fw-bold mb-0">
                      {data
                        ? "Sentiment: " + data.contact_profile.sentiment
                        : null}
                    </p>
                  </>
                ) : (
                  <>
                    <Skeleton height={26} width={180} />
                    <Skeleton height={18} width={180} />
                    <Skeleton height={18} width={180} />
                    <Skeleton height={14} width={180} className="mb-3" />
                    <hr />
                    <Skeleton height={18} width={180} />
                    <Skeleton height={18} width={180} />
                  </>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <hr />
                {!isLoading ? (
                  <p className="fs-12 text-justify fw-bold mb-0">
                    {data ? data.contact_profile.profile : null}
                  </p>
                ) : (
                  <>
                    <Skeleton count={10} height={14} />
                  </>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </SimpleBar>
    </Modal>
  );
};

export default ModalLiveProfile;
