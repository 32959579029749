import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import Skeleton from "react-loading-skeleton";
import { FaCheck, FaTimes } from "react-icons/fa";
import { ToastContainer, toast, Slide } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import authHeader from "../services/auth.header";
import axios from "axios";
import { API_URL } from "../utilities/url";
import storage from "../services/storage";
import CryptoJS from "crypto-js";

const ModalAssistantSetting = ({
  show,
  handleClose,
  contactSupervisor,
  setContactSupervisor,
}) => {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    reset,
  } = useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const decryptWS = CryptoJS.AES.decrypt(storage.get("wrs"), "wrs").toString(
    CryptoJS.enc.Utf8
  );
  const workspace = JSON.parse(decryptWS);
  const [assistant, setAssistant] = useState([]);
  const [hideSpiner, setHideSpiner] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isActive, setIsActive] = useState(false);

  const handleToggle = () => {
    setIsActive(!isActive);
    setValue("active", !isActive);
  };

  useEffect(() => {
    getAssistantList();
    setValue("active", false);
    setValue("idt_user_workspace", workspace.idt_user_workspace);
  }, [location.key]);

  useEffect(() => {
    if (assistant.length > 0) {
      if (contactSupervisor) {
        setValue("title", contactSupervisor.title);
        setValue("prompt", contactSupervisor.prompt);
        setIsActive(contactSupervisor.is_active === "Y");
        setValue("active", contactSupervisor.is_active === "Y");
        setValue(
          "idt_user_assistant_assignment",
          contactSupervisor.idt_user_assistant_assignment
        );

        if (contactSupervisor.idt_user_assistant) {
          const selectedOption = assistant.find(
            (option) => option.value === contactSupervisor.idt_user_assistant
          );
          setValue("assistant", selectedOption);
        }
      } else {
        setValue("title", null);
        setValue("prompt", null);
        setIsActive(null === "Y");
        setValue("active", null === "Y");
        setValue("idt_user_assistant_assignment", null);
        setValue("assistant", null);
      }
      setIsLoading(false);
    }
  }, [assistant, contactSupervisor]); // Jalankan ulang ketika contactSupervisor berubah

  async function getAssistantList() {
    axios
      .post(
        `${API_URL}get-personal-assistant-list`,
        { idt_workspace: workspace.idt_user_workspace, limit: 500, page: 1 },
        {
          headers: {
            Authorization: `Bearer ${storage.get("access_token")}`,
          },
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          const maps = response.data.data.data.map(function (d) {
            const map = {
              value: d.idt_user_assistant,
              label: d.name,
            };
            return map;
          });
          setAssistant(maps);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          navigate("/login");
        }
      });
  }

  const customStyles = {
    control: (base, state) => ({
      ...base,
      height: 32,
      borderRadius: 4,
      minHeight: 32,
      borderColor: state.isFocused ? "#7f7f7f" : base.borderColor, // Ubah warna border saat fokus
      boxShadow: state.isFocused ? "0 0 0 0.1px #7f7f7f" : base.boxShadow, // Hilangkan shadow bawaan
      "&:hover": {
        borderColor: state.isFocused ? "#7f7f7f" : base.borderColor, // Warna hover
      },
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      padding: 0,
      margin: -2,
    }),
  };

  const onSubmit = (data) => {
    setHideSpiner(false);
    axios
      .post(API_URL + "save-contact-supervisor", data, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.data.success === true) {
          setContactSupervisor(res.data.data);
          toast.success(res.data.message, {
            position: "top-right",
            theme: "colored",
            transition: Slide,
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
          });
          setHideSpiner(true);
          handleClose();
        } else {
          toast.error(res.data.message, {
            position: "top-right",
            theme: "colored",
            transition: Slide,
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
          });
          setHideSpiner(true);
          handleClose();
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      size="lg"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>Assistant Setting</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-md-6">
              {/* Title */}
              <div className="mb-3 row">
                <label className="col-form-label col-sm-4 text-sm-right">
                  Title<span className="required">*</span>
                </label>
                <div className="col-sm-8">
                  {isLoading ? (
                    <Skeleton height={28} />
                  ) : (
                    <>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Title"
                        {...register("title", {
                          required: "Title is required",
                        })}
                      />
                      {errors.title && (
                        <span className="text-danger">
                          {errors.title.message}
                        </span>
                      )}
                    </>
                  )}
                </div>
              </div>

              {/* Assistant */}
              <div className="mb-3 row">
                <label className="col-form-label col-sm-4 text-sm-right">
                  Assistant<span className="required">*</span>
                </label>
                <div className="col-sm-8">
                  {isLoading ? (
                    <Skeleton height={28} />
                  ) : (
                    <>
                      <Controller
                        name="assistant"
                        control={control}
                        rules={{ required: "Assistant is required" }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={assistant}
                            placeholder="Select Assistant"
                          />
                        )}
                      />
                      {errors.assistant && (
                        <span className="text-danger">
                          {errors.assistant.message}
                        </span>
                      )}
                    </>
                  )}
                </div>
              </div>

              {/* Prompt */}
              <div className="mb-3 row">
                <label className="col-form-label col-sm-4 text-sm-right">
                  Prompt<span className="required">*</span>
                </label>
                <div className="col-sm-8">
                  {isLoading ? (
                    <Skeleton height={146} />
                  ) : (
                    <>
                      <textarea
                        className="form-control"
                        placeholder="Prompt for specific profiling"
                        {...register("prompt", {
                          required: "Prompt is required",
                        })}
                        style={{ minHeight: "150px" }}
                      />
                      {errors.prompt && (
                        <span className="text-danger">
                          {errors.prompt.message}
                        </span>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="col-md-6">
              {/* Active */}
              <div className="mb-3 row">
                <label className="col-form-label col-sm-4 text-sm-right">
                  Active
                </label>
                <div className="col-sm-8">
                  {isLoading ? (
                    <Skeleton height={28} width={100} />
                  ) : (
                    <div
                      className={`custom-switch-form px-3 ${
                        isActive ? "on" : ""
                      }`}
                      onClick={handleToggle}
                    >
                      <div className="slider-form">
                        <div className="slider-icon-form">
                          {isActive ? <FaCheck /> : <FaTimes />}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="mt-4 d-flex justify-content-end flex-column flex-sm-row">
            <button
              type="submit"
              className="btn btn-success btn-md text-end w-sm-auto"
              disabled={!hideSpiner}
            >
              Submit
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalAssistantSetting;
